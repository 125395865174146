import {atom} from "recoil";
import {useQuery} from "react-query";

import {query as _query} from "firebase/firestore";
import {useEntityCount} from "../../../../shared/hooks/hooks.utils";
import {crudService, execQueryForList} from "../../../../shared/services/firebase/db.firebase";
import {
    initialSearchQueryData,
    useInvalidateQueriesByRootName,
    useSearchDocs
} from "../../../../shared/services/firebase/firebase.cache.store";

const colName = "departures";

export const {colRef, docRef, create, update, remove, findById, findAll, count} = crudService(colName);

const departuresState = atom({
    key: "departures.state",
    default: {...initialSearchQueryData},
});

export const gmapsStaticImage = ({latitude, longitude, height = 350, width = 600}) => {
    const imageUrl = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=14&size=${width}x${height}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
    return {imageUrl, latitude, longitude, height, width};
}

export const useSearchDepartures = () => useSearchDocs({
    colRef, state: departuresState, queryRootName: colName, queryName: "search"
});

export const useFindById = (id) => {
    const queryKey = [colName, "by.id", id];
    const queryFn = () => {
        if (!id) return null;
        return findById(id);
    }
    return useQuery(queryKey, queryFn);
}

export const useDepartureCount = () => {
    return useEntityCount("DEPARTURE", count);
}

export const useFindAllDepartures = () => {
    const queryKey = [colName, "all"];
    const queryFn = async () => await execQueryForList(_query(colRef));
    return useQuery(queryKey, queryFn, {});
}


export const useInvalidateQueries = () => useInvalidateQueriesByRootName(colName);


import {useQuery} from "react-query";
import {useEffect, useState} from "react";
import {limit, onSnapshot, orderBy, query} from "firebase/firestore";

import {Comment} from "./event.domains";
import {ko, ok} from "../../../../utils/service.utils";
import {
    auditorCreate,
    collectionGroupRef,
    collectionRef,
    countFromServer,
    extractData,
    service
} from "../../../../shared/services/firebase/db.firebase";

const collectionPath = (eventPath) => `${eventPath}/${Comment.colName}`;

export async function addComment(eventRef, user, comment) {
    try {
        if (!comment?.toString()?.trim()) return;

        const {displayName, email, photoURL, uid: userId} = user;

        const item = {userId, content: comment, author: {displayName, email, photoURL, uid: userId}};

        const {create} = service(collectionPath(eventRef));
        const saved = create(auditorCreate(item));
        return ok({id: saved.id}, "OK",);
    } catch (error) {
        return ko("Erreur lors de la création du ticket");
    }
}

export function useRealtimeComments(eventRef) {
    const [isLoading, setIsLoading] = useState(true);
    const [comments, setComments] = useState([]);

    useEffect(() => {
        if (!eventRef) return;

        let _query = collectionRef(eventRef, Comment.colName);
        _query = query(_query, orderBy("createdDate", "asc"), limit(10));
        onSnapshot(_query, (querySnapshot) => {
            setComments(querySnapshot.docs.map(extractData));
            setIsLoading(false);
        });
    }, [eventRef, setComments]);

    return {comments, isLoading};
}

export const useAllCommentCount = () => {
    const queryKey = [Comment.colName, "count.all"];
    const queryFn = async () => await countFromServer(collectionGroupRef(Comment.colName));
    const {data: count, ...others} = useQuery(queryKey, queryFn);
    return {count, ...others};
}

export const useEventCommentCount = (eventRef) => {
    const queryKey = [Comment.colName, eventRef];
    const queryFn = async () => await countFromServer(collectionRef(eventRef, Comment.colName));
    const {data: count, ...others} = useQuery(queryKey, queryFn, {staleTime: 10_000});
    return {count, ...others};
}

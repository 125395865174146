import {useQuery} from "react-query";
import {useEffect, useState} from "react";
import {onSnapshot, orderBy, query} from "firebase/firestore";

import {Like} from "./event.domains";
import {ko, ok} from "../../../../utils/service.utils";
import {
    auditorCreate, collectionGroupRef,
    collectionRef,
    countFromServer, onSnapshotDoc,
    service
} from "../../../../shared/services/firebase/db.firebase";

const collectionPath = (eventPath) => `${eventPath}/${Like.colName}`;

export const useLikedByMe = (eventRef, userId) => {
    const [likedByMe, setLikedByMe] = useState(false);

    useEffect(() => {
        if (!userId) return;

        const {docRef} = service(collectionPath(eventRef));
        return onSnapshotDoc(docRef(userId), likeData => setLikedByMe(!!likeData));
    }, [setLikedByMe, eventRef, userId]);

    return likedByMe;
}

export const likedByMe = async (eventRef, user) => {
    const {docRef, setDoc, findById, destroy} = service(collectionPath(eventRef));
    const snap = await findById(user.uid);

    const isLikedByMe = snap.exists();
    return {isLikedByMe, snap, docRef, setDoc, findById, destroy};
};

export const addLike = async (eventRef, user) => {
    try {
        const {isLikedByMe, snap, docRef, setDoc, destroy} = await likedByMe(eventRef, user);
        console.log("========> addLike: ", {isLikedByMe, snap});
        if (isLikedByMe) {
            await destroy(docRef(snap.id));
            return;
        }

        const {displayName, email, photoURL, uid: userId} = user;
        const like = {userId, author: {displayName, email, photoURL, uid: userId}};
        const saved = setDoc(docRef(userId), auditorCreate(like));
        return ok({id: saved.id}, "OK",);
    } catch (error) {
        return ko("Erreur lors de la création du ticket");
    }
}

export const useAllLikeCount = () => {
    const queryKey = [Like.colName, "count.all"];
    const queryFn = async () => await countFromServer(collectionGroupRef(Like.colName));
    const {data: count, ...others} = useQuery(queryKey, queryFn);
    return {count, ...others};
}

export const useEventLikeCount = (eventRef) => {
    const queryKey = [Like.colName, eventRef];
    const queryFn = async () => await countFromServer(collectionRef(eventRef, Like.colName));
    const {data: count, ...others} = useQuery(queryKey, queryFn, {staleTime: 10_000});
    return {count, ...others};
}


import {useState} from "react";
import {formatDistanceToNowStrict} from "date-fns";
import {Comment, Favorite, Send} from "@mui/icons-material";
import {
    Avatar,
    Box,
    Card,
    CardHeader,
    CardMedia,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    InputBase,
    LinearProgress,
    Skeleton,
    Stack,
    Typography
} from "@mui/material";

import EventToolbar from "./EventToolbar";
import useAuth from "../../../../../shared/hooks/useAuth";
import {fShortenNumber} from "../../../../../utils/formatNumber";
import Markdown from "../../../../../shared/components/markdown";
import {BACKOFFICE_PATH} from "../../../../paths";
import SimpleLink from "../../../../../shared/components/SimpleLink";
import {CustomAvatar} from "../../../../../shared/components/custom-avatar";
import {addLike, useEventLikeCount, useLikedByMe} from "../event.like.service";
import {useSearchPendingEvents, useSearchValidatedEvents} from "../event.service";
import {dateToDDMMYYYY, dateToHHmmss} from "../../../../../utils/formatTime";
import {addComment, useEventCommentCount, useRealtimeComments} from "../event.comment.service";


export function EventGrid({events, hrefBuilder, withInteractions = true}) {
    return (
        <Grid container spacing={4}>
            {events?.map(event => (
                <Grid item key={event.id} xs={12} md={6} lg={4}>
                    <EventCard event={event} withInteractions={withInteractions} href={hrefBuilder(event)}/>
                </Grid>
            ))}
        </Grid>
    )
}

export function EventCard({event, href, withInteractions = true}) {
    return (
        <Card sx={{height: "100%", display: "flex", flexDirection: "column", boxShadow: theme => theme.shadows[2]}}>
            <EventImage event={event} href={href}/>

            <Stack flexGrow={1} justifyContent={"space-between"} pb={2}>
                <EventHeader event={event}/>

                {withInteractions && <EventInteractions event={event} href={href}/>}
            </Stack>
        </Card>
    );
}

export function EventTicketOfferPrice({price}) {
    return (
        <Stack spacing={1} direction="row" sx={{color: "primary.dark"}}>
            <Typography variant={"h3"}>{price === 0 ? "Gratuit" : price}</Typography>
            <Typography variant={"caption"}>{"F CFA"}</Typography>
        </Stack>
    )
}


export function EventComments({event}) {

    const {comments} = useRealtimeComments(event.ref);

    return (
        <Card sx={{p: 3}}>
            <Stack spacing={5}>
                <Stack spacing={2}>
                    {comments?.map((comment) => (
                        <EventComment key={comment.id} comment={comment}/>
                    ))}
                </Stack>
                <EventAddComment event={event}/>
            </Stack>
        </Card>
    );
}

export function EventImage({event, href}) {
    let content = (
        <CardMedia
            component="img"
            image={!!event?.images?.length && event?.images[0]}
            alt="Event image"
            sx={{
                cursor: "pointer",
                height: {
                    xs: 250,
                    md: 300,
                    lg: 330,
                }
            }}
        />
    );

    if (href) {
        content = (
            <SimpleLink href={href}>
                {content}
            </SimpleLink>
        );
    }
    return content;
}

export function EventHeader({event}) {
    if (!event) return <></>;

    return (
        <CardHeader
            title={event.name}
            subheader={(
                <>
                    <EventDateTime event={event}/>
                    <EventDeparture event={event}/>
                    {/*<EventCount event={event}/>*/}
                </>
            )}
        />
    )
}

export function EventDateTime({event}) {
    if (!event) return <></>;

    const {startingDate} = event;
    const dateTime = startingDate?.toDate()

    return (
        <Typography variant={"body2"}>
            {`Le : ${dateToDDMMYYYY(dateTime)} à ${dateToHHmmss(dateTime)}`}
        </Typography>

    )
}

export function EventDeparture({event}) {
    if (!event) return <></>;

    const {departureName} = event;

    return (
        <Typography variant="body2">
            {`Lieu de départ: ${departureName}`}
        </Typography>
    )
}

export function EventDescription({event}) {
    return (
        <Stack p={3}>
            <Markdown>
                {event?.description}
            </Markdown>
        </Stack>
    )
}

export function EventInteractions({event, href}) {
    return (
        <Stack direction="row" alignItems="center" justifyContent={"space-between"} px={3}>
            <EventLikeButton event={event}/>

            <EventCommentButton event={event} href={href}/>
        </Stack>
    )
}

export function EventLikeButton({event}) {
    const {user} = useAuth();
    const likedByMe = useLikedByMe(event?.ref, user?.uid);
    const {count} = useEventLikeCount(event?.ref);

    const onLike = () => {
        if (!user) return;
        return addLike(event.ref, user);
    }

    return (
        <FormControlLabel
            control={
                <Checkbox
                    color="error"
                    onChange={onLike}
                    icon={<Favorite/>}
                    checked={likedByMe}
                    checkedIcon={<Favorite/>}
                />
            }
            label={fShortenNumber(count)}
        />
    )
}

export function EventCommentButton({event, href}) {
    const {count: commentCount} = useEventCommentCount(event?.ref);

    let content = (
        <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <Comment/>
            <Typography>
                {fShortenNumber(commentCount)}
            </Typography>
        </Stack>
    );

    if (href) {
        content = (
            <SimpleLink href={href}>
                {content}
            </SimpleLink>
        );
    }

    return content;
}

export function EventComment({comment}) {
    const {createdDate, content, author} = comment ?? {};
    const {photoURL, displayName} = author ?? {};

    return (
        <Stack direction={"row"}>
            <Avatar alt={displayName} src={photoURL} sx={{width: 32, height: 32, mr: 2}}/>

            <Stack spacing={1} alignItems={"flex-start"}>
                <Typography noWrap variant="caption" sx={{color: 'text.disabled', mr: 'auto'}}>
                    {`${displayName},`} &nbsp;
                    {createdDate && formatDistanceToNowStrict(new Date(createdDate.toDate()), {
                        addSuffix: true,
                    })}
                </Typography>

                <Stack
                    sx={{
                        p: 1.5,
                        minWidth: 48,
                        maxWidth: 320,
                        borderRadius: 1,
                        overflow: 'hidden',
                        typography: 'body2',
                        backgroundColor: 'background.neutral',
                    }}
                >
                    {content}
                </Stack>
            </Stack>
        </Stack>
    );
}

export function EventAddComment({event}) {
    const {user} = useAuth();
    const [comment, setComment] = useState("");

    if (!user) return <></>;

    const onAddComment = async () => {
        if (!comment) return;

        await addComment(event.ref, user, comment);
        setComment("");
    };

    const onKeyUp = async (event) => {
        if (event.key === "Enter") return onAddComment();
    };

    return (
        <Stack spacing={2} direction="row" alignItems="center">
            <CustomAvatar src={user?.photoURL} alt={user?.displayName} name={user?.displayName}/>

            <InputBase
                fullWidth
                value={comment}
                onKeyUp={onKeyUp}
                placeholder={"Ecrivez un commentaire…"}
                onChange={(event) => setComment(event.target.value)}
                sx={{
                    px: 1.5, height: 40, borderRadius: 5,
                    border: theme => `solid 1px ${theme.palette.grey[300]}`,
                }}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton onClick={onAddComment}>
                            <Send/>
                        </IconButton>
                    </InputAdornment>
                }
            />
        </Stack>
    )
}

export function PendingEventGrid({...others}) {

    const {
        query,
        setQuery,
        isLoading,
        departure,
        data: events,
        setDeparture
    } = useSearchPendingEvents();

    return (
        <Stack spacing={3} {...others}>
            <EventToolbar
                query={query}
                setQuery={setQuery}
                departure={departure}
                setDeparture={setDeparture}
            />

            {isLoading && <LinearProgress sx={{mb: 2}}/>}

            <Box>
                <EventGrid
                    events={events}
                    withInteractions={false}
                    hrefBuilder={event => BACKOFFICE_PATH.events.pending.display(event.id)}
                />
            </Box>
        </Stack>
    );
}

export function ValidatedEventGrid({isAdmin, ...others}) {

    const {
        query,
        setQuery,
        isLoading,
        departure,
        data: events,
        setDeparture,
    } = useSearchValidatedEvents();

    return (
        <Stack spacing={3} {...others}>
            <EventToolbar
                query={query}
                setQuery={setQuery}
                departure={departure}
                setDeparture={setDeparture}
            />

            {isLoading && <LinearProgress sx={{mb: 2}}/>}

            <Box>
                <EventGrid
                    events={events}
                    hrefBuilder={event => BACKOFFICE_PATH.events.validated.display(event.id)}
                />
            </Box>
        </Stack>
    );
}

export function SkeletonEventDetail() {
    return (
        <>
            <Stack spacing={3} mb={2} direction={"row"}>

                <Stack width={"30%"}>
                    <Skeleton variant="text" height={40} width={"100%"}/>
                    <Skeleton variant="text" height={40} width={"100%"}/>
                </Stack>

                <Box flexGrow={1}/>

                <Skeleton variant="text" height={40} width={"20%"}/>
            </Stack>

            <Skeleton variant="rectangular" width={"100%"} height={250}/>

            <Stack alignItems={"center"}>
                <Skeleton variant="text" height={40} width={"80%"}/>
            </Stack>

            <Stack spacing={3} mb={20} pt={5} justifyContent={"center"} sx={{textAlign: "center"}}>
                <Skeleton variant="text" height={40}/>
            </Stack>
        </>
    );
}
